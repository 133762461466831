import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Mine() {
    const id = useSelector((state) => state.userinfo.id);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState("contacts");
    const [selectedContact, setSelectedContact] = useState(null);
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [contacts, setContacts] = useState([]);
    const [friendRequests, setFriendRequests] = useState([]);
    const [newFriendEmail, setNewFriendEmail] = useState("");
    const messageEndRef = useRef(null);
    const ws = useRef(null);
    const userimg=useSelector((state)=>state.userinfo.userimg);
    const [height, setHeight] = useState('513.5px');

    function modifyuserimg(){

    }

    useEffect(() => {
      const userAgent = navigator.userAgent;
  
      // 根据浏览器类型和窗口大小设置不同的高度
      if (userAgent.includes('Edg')) {
          setHeight('488.5px')
      }
    }, []);

// 使用条件依赖数组来控制副作用的执行
useEffect(() => {
    if (!localStorage.getItem('authToken')) {
      navigate('/specialselect');
      return null;
    }
    // 如果认证成功执行的其他逻辑
  }, [navigate]); // 依赖于 navigate, 确保钩子每次渲染都执行，但只有在需要时执行导航逻辑
  

    // 获取用户好友列表和好友申请列表
    useEffect(() => {
        getuserfriends();
        fetchFriendRequests();
    }, [id]);

    // 获取好友列表
    async function getuserfriends() {
        try {
            const response = await fetch('https://api.music.coderyy.site/fetchFriends.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id })
            });
            const data = await response.json();
            setContacts(Array.isArray(data) ? data : []);
        } catch (error) {
            console.error("Failed to fetch friends:", error);
        }
    }

    // 获取好友申请
    async function fetchFriendRequests() {
        try {
            const response = await fetch('https://api.music.coderyy.site/fetchFriendRequests.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id })
            });
            const data = await response.json();
            setFriendRequests(data || []);
        } catch (error) {
            console.error("Failed to fetch friend requests:", error);
        }
    }

    // 发送好友申请
    async function handleAddFriend() {
        try {
            const response = await fetch('https://api.music.coderyy.site/sendFriendRequest.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id, email: newFriendEmail })
            });
            const data = await response.json();
            if (data.status === 'success') {
                alert("好友申请已发送");
            } else {
                alert("发送失败，可能对方不存在");
            }
        } catch (error) {
            console.error("Failed to send friend request:", error);
        }
    }

    // 同意好友申请
    async function handleApproveRequest(requestId) {
        try {
            const response = await fetch('https://api.music.coderyy.site/approveFriendRequest.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id, requestId })
            });
            const data = await response.json();
            if (data.status === 'success') {
                // 更新联系人列表
                getuserfriends();
                fetchFriendRequests(); // 更新申请列表
            }
        } catch (error) {
            console.error("Failed to approve friend request:", error);
        }
    }


    // 切换联系人时加载消息记录
    useEffect(() => {
        if (selectedContact) {
            const storedMessages = JSON.parse(localStorage.getItem(selectedContact)) || [];
            setMessages(storedMessages);
        }
    }, [selectedContact]);

    const requestOfflineMessages = async () => {
        if (selectedContact) {
            try {
                const response = await fetch('https://api.music.coderyy.site/fetchOfflineMessages.php', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({receiver: id })
                });
                const data = await response.json();
                if (data.messages && Array.isArray(data.messages)) {
                    setMessages((prevMessages) => [...prevMessages, ...data.messages]);
                    let storedMessages = JSON.parse(localStorage.getItem(selectedContact)) || [];
                    storedMessages.push(data.messages);
                    localStorage.setItem(selectedContact, JSON.stringify(storedMessages));
                }
            } catch (error) {
                console.error("Failed to fetch offline messages:", error);
            }
        }
    };
        
        // WebSocket 连接和消息处理
        useEffect(() => {
            ws.current = new WebSocket("wss://music.coderyy.site:1234");
        
            ws.current.onopen = () => {
                // 连接后向服务器发送用户id
                ws.current.send(JSON.stringify({ id }));
        
                // 请求用户的历史消息
                requestOfflineMessages();  // 确保在连接后请求离线消息
            };
        
            // 接收消息时更新当前聊天记录
            ws.current.onmessage = (event) => {
                const newMessage = JSON.parse(event.data);
        
                // 确保仅当消息的收发双方为选中的联系人时才处理
                if (
                    (newMessage.sender === selectedContact || newMessage.receiver === selectedContact) &&
                    (newMessage.receiver === id || newMessage.sender === id)
                ) {
                    setMessages((prevMessages) => [...prevMessages, newMessage]);
        
                    // 存储消息到 localStorage
                    let storedMessages = JSON.parse(localStorage.getItem(selectedContact)) || [];
                    storedMessages.push(newMessage);
                    localStorage.setItem(selectedContact, JSON.stringify(storedMessages));
                }
            };
        
            return () => {
                if (ws.current) {
                    ws.current.close();
                }
            };
        }, [id, selectedContact]);


    // 自动滚动到最新消息
    useEffect(() => {
        messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const handleSendMessage = () => {
        if (inputMessage.trim() && selectedContact) {
            const newMessage = {
                content: inputMessage,
                timestamp: new Date(),
                type: "text",
                sender: id,
                receiver: selectedContact
            };
    
            // 发送消息到 WebSocket
            if (ws.current && ws.current.readyState === WebSocket.OPEN) {
                ws.current.send(JSON.stringify(newMessage));
            } else {
                console.error("WebSocket is not open.");
            }
    
            // 更新当前消息列表
            setMessages((prevMessages) => [...prevMessages, newMessage]);
    
            // 清空输入框
            setInputMessage("");
    
            // 获取本地存储中的历史消息
            let storedMessages = JSON.parse(localStorage.getItem(selectedContact)) || [];
    
            // 将新消息添加到历史消息中
            storedMessages.push(newMessage);
    
            // 更新本地存储中的消息记录
            try {
                localStorage.setItem(selectedContact, JSON.stringify(storedMessages));
            } catch (error) {
                window.error("Error saving to localStorage:", error);
            }
        } else {
            window.log("Message is empty or no contact selected.");
        }
    };
    
    

    function handleKeyDown(e){
        if (e.key === 'Enter' && !e.shiftKey) {  // 检查是否按了 Enter 键（不按 Shift 键）
            e.preventDefault();  // 防止换行
            handleSendMessage();
        }
    }

    return (
        <main style={{ display: 'flex', marginTop: '60px', marginLeft: '229px', marginBottom: '91.5px', padding: '20px', height: height }}>
            <div style={{ width: '50px', borderRight: '1px solid #ccc', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px' }}>
                <button onClick={() => setCurrentPage("contacts")} title="联系人" style={{ fontSize: '16px', cursor: 'pointer' }}>👤</button>
                <button onClick={() => setCurrentPage("addFriend")} title="添加好友" style={{ fontSize: '16px', cursor: 'pointer' }}>➕</button>
                <button onClick={() => setCurrentPage("friendRequests")} title="好友申请" style={{ fontSize: '16px', cursor: 'pointer' }}>🔔</button>
                <img onClick={() => setCurrentPage("setting")} style={{cursor:'pointer'}} src="https://api.music.coderyy.site/music.coderyy.site's imgs/setting.png" title='设置' alt="设置" />
            </div>

            {currentPage === "contacts" && (
                <div style={{ width: '200px', borderRight: '1px solid #ccc', padding: '10px' }}>
                    <h3 style={{ fontSize: '18px', marginBottom: '10px' }}>联系人</h3>
                    <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                        {contacts.map((contact) => (
                            <li key={contact.id} onClick={() => setSelectedContact(contact.id)} style={{
                                cursor: 'pointer',
                                marginBottom: '10px',
                                padding: '8px',
                                backgroundColor: selectedContact === contact.id ? '#e6e6e6' : 'transparent',
                                borderRadius: '5px'
                            }}>
                                {contact.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {selectedContact&&currentPage==='contacts' && (
                <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <div style={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid #ccc',
                        paddingBottom: '10px',
                        marginBottom: '10px',
                    }}>
                        <span style={{ marginLeft: '36px' }}>{contacts.find(c => c.id === selectedContact)?.name}</span>
                    </div>
                    <div style={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        padding: '10px',
                        borderRadius: '5px',
                        marginBottom: '10px',
                        backgroundColor: '#fff'
                    }}>
                        {messages.map((msg, index) => (
                            <div key={index} style={{
                                marginBottom: '10px',
                                display: 'flex',
                                justifyContent: msg.sender === id ? 'right' : 'left'
                            }}>
                                <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <span style={{ fontSize: '12px', color: '#888', alignSelf: msg.sender === id ? 'flex-end' : 'flex-start' }}>
                                        {new Date(msg.timestamp).toLocaleString()}
                                    </span>
                                    <p style={{
                                        padding: '8px',
                                        backgroundColor: msg.sender === id ? '#a8e6cf' : '#f0f0f0',
                                        borderRadius: '8px',
                                        display: 'inline-block',
                                        maxWidth: '100%',
                                        wordWrap: 'break-word',
                                        textAlign: 'left',
                                        alignSelf: msg.sender === id ? 'flex-end' : 'flex-start'
                                    }}>
                                        {msg.content}
                                    </p>
                                </div>
                            </div>
                        ))}
                        <div ref={messageEndRef}></div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <input type="text" onKeyDown={(e)=>handleKeyDown(e)} value={inputMessage} onChange={(e) => setInputMessage(e.target.value)} placeholder="输入消息..." style={{
                            flexGrow: 1,
                            padding: '10px',
                            marginRight: '10px',
                            borderRadius: '5px',
                            border: '1px solid #ccc'
                        }} />
                        <button onClick={handleSendMessage} style={{
                            padding: '10px 20px',
                            borderRadius: '5px',
                            backgroundColor: '#4caf50',
                            color: '#fff',
                            cursor: 'pointer'
                        }}>
                            发送
                        </button>
                    </div>
                </div>
             )}
            {currentPage === "addFriend" && (
                <div style={{ width: '300px', display:'flex',flexDirection:'column',alignItems:'center',gap:'30px',padding: '20px', backgroundColor: '#fff', borderRadius: '8px', marginLeft: '10px' }}>
                    <h3 style={{ fontSize: '18px', marginBottom: '10px' }}>添加好友</h3>
                    <input type="email" value={newFriendEmail} onChange={(e) => setNewFriendEmail(e.target.value)} placeholder="输入对方邮箱" style={{
                        width: '100%',
                        padding: '10px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        marginBottom: '10px'
                    }} />
                    <button onClick={handleAddFriend} style={{
                        padding: '10px 20px',
                        borderRadius: '5px',
                        backgroundColor: '#4caf50',
                        color: '#fff',
                        width: '100%'
                    }}>
                        发送好友申请
                    </button>
                </div>
            )}

            {currentPage === "friendRequests" && (
                <div style={{ width: '300px', padding: '20px', backgroundColor: '#fff', borderRadius: '8px', marginLeft: '10px' }}>
                    <h3 style={{ fontSize: '18px', marginBottom: '10px' }}>好友申请</h3>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {friendRequests.map((request) => (
                            <li key={request.id} style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '10px',
                                borderBottom: '1px solid #eee',
                                marginBottom: '10px'
                            }}>
                                <span>{request.requestemail}</span>
                                <button onClick={() => handleApproveRequest(request.requestid)} style={{
                                    backgroundColor: '#4caf50',
                                    color: '#fff',
                                    padding: '5px 10px',
                                    borderRadius: '5px',
                                    cursor: 'pointer'
                                }}>
                                    同意
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {currentPage === "setting" && (
                <>
                <div>
                    <h2 style={{marginLeft:'120px',marginBottom:'30px'}}>编辑个人信息</h2>
                    <form action='https://api.music.coderyy.site/modifyinfo.php' method='post' style={{marginLeft:'50px',display:'flex',flexDirection:'column',gap:'30px'}}>
                        <div style={{display:'flex'}}>
                            <label htmlFor="username">昵称：</label>
                            <input name='username' type="text" id='username' style={{width:'220px',height:'33px'}}/>
                        </div>
                        <div style={{display:'flex'}}>
                            <label htmlFor="description">简介：</label>
                            <input name='description' type="text" id='description' style={{ width:'220px',height:'100px'}}/>
                        </div>
                        <div style={{display:'flex'}}>
                            <span>性别：</span>
                            <input type="radio" id='man' name='gender' value='男'/>
                            <label htmlFor="man">男</label>
                            <input type="radio" id='woman' name='gender' value='女'/>
                            <label htmlFor="woman">女</label>
                        </div>
                        <div style={{display:'flex'}}>
                            <label htmlFor="birthday">生日：</label>
                            <input name='birthday' type="date" id='birthday' style={{width:'220px',height:'30px'}}/>
                        </div>
                        <div style={{display:'flex'}}>
                            <label htmlFor="place">地区：</label>
                            <input name='place' id='place' type="text" style={{width:'220px',height:'30px'}}/>
                        </div>
                        <input style={{display:'none'}} type="text" name='id' value={id} />
                        <div style={{justifyContent:'center',alignItems:'center',display:'flex'}}>
                            <button type='submit'>保存</button>
                        </div>
                    </form>
                </div>
                <div  style={{}} onClick={modifyuserimg}>
                    <img style={{marginLeft:'200px',marginTop:'90px',width:'81px',height:'81px',cursor:'pointer'}} src={userimg} alt="头像" />
                </div>
                </>
            )}
        </main>
    );
}

export default Mine;
