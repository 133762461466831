import React, { useEffect, useState } from 'react';
import Audioinfo from './audio/Audioinfo';
import { useSelector } from 'react-redux';

export default function Searchmusic() {
  const [height, setHeight] = useState('513.5px');
  const targetsong=useSelector(state=>state.audioinfo.targetsong)

  useEffect(() => {
    const userAgent = navigator.userAgent;

    // 根据浏览器类型和窗口大小设置不同的高度
    if (userAgent.includes('Edg')) {
        setHeight('488.5px')
    }
  }, []);

  return (
    <main
      style={{
        marginTop: '60px',
        marginLeft: '229px',
        marginBottom: '91.5px',
        padding: '20px',
        overflowY: 'auto',
        flexGrow: '1',
        height: height
      }}
    >
    <div>
      <Audioinfo props={targetsong}/>
    </div>
    </main>
  );
}
