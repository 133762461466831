import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './Layout';
import Recommend from './Recommend'; 
import Mylike from './Mylike';
import Boke from './Boke';
import Manyou from './Manyou';
import Recentplay from './Recentplay';
import Myboke from './Myboke';
import Mycollect from './Mycollect';
import Mine from './Mine';
import useAuth from './hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import Searchmusic from './Searchmusic';
import Wuziqi from './game/wuziqi/Wuziqi';
import { setmylikelist,setmyliketot } from './store/modules/audioinfomodule';
import Game from './Game';

function App() {

  useAuth();
  const dispatch=useDispatch()
  const isverify = useSelector((state) => state.userinfo.isverify);
  const userid=useSelector(state=>state.userinfo.id)

  async function loadallmylike() {
    try{
        const response=await fetch("https://api.music.coderyy.site/loadallmylike.php",{
            method:'post',
            body:JSON.stringify({
              userid:userid
            })
        })
        const data=await response.json();
        dispatch(setmylikelist(data.musics))
        dispatch(setmyliketot(data.totalmusics))
    }catch(error){
        window.alert(error);
    }
}

  useEffect(()=>{
    if(isverify){
      loadallmylike()
    }
  },[isverify])
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Recommend />} />
          <Route path='/recommend' element={<Recommend />} />
          <Route path='/mylike' element={<Mylike />} />
          <Route path='/game' element={<Game/>} />
          <Route path='/boke' element={<Boke />} />
          <Route path='/manyou' element={<Manyou />} />
          <Route path='/recentplay' element={<Recentplay />} />
          <Route path='/myboke' element={<Myboke />} />
          <Route path='/mycollect' element={<Mycollect />} />
          <Route path='/mine' element={isverify ? <Mine /> : <Recommend />} />
          <Route path='/searchmusic' element={<Searchmusic/>}/>
        </Route>
        <Route path='/wuziqi' element={<Wuziqi/>}/>
        <Route path="*" element={<Navigate to="/recommend" />} />
      </Routes>
    </Router>
  );
}

export default App;
