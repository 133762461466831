import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { settargetsong } from "../store/modules/audioinfomodule";



export default function SearchBar() {
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const isverify=useSelector(state=>state.userinfo.isverify)
    const allmusiclist=useSelector(state=>state.audioinfo.allmusiclist)

    const handlekeypress=(event)=>{
        if(event.key==='Enter'){
          const musicvalue=event.target.value;
          serchformusic(musicvalue)
          event.target.value=''
        }
    }

    function serchformusic(musicname){
        if(!isverify){
            return;
        }
        const targetsong=allmusiclist.find((music)=>music.musicname===musicname);
        if(!targetsong){
          window.alert("歌曲未找到！");
          return;
        }
        dispatch(settargetsong(targetsong))
        navigate('/searchmusic')
      }

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            width: '400px',
            position: 'relative',
            boxSizing: 'border-box'
        }}>

            {/* 搜索框部分 */}
            <div style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                padding: '5px 15px',
                width: '60%',
                height:'30px',
                position: 'absolute',
                left: '49px',            
                boxShadow: '0 1px 5px rgba(0, 0, 0, 0.1)',
            }}>
                <input
                onKeyDown={handlekeypress}
                    type="text"
                    placeholder=""
                    style={{
                        flexGrow: '1',
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'transparent',
                        color: '#666',
                        fontSize: '14px',
                        marginLeft: '10px'
                    }}
                />
            </div>
        </div>
    );
}
