import { useEffect, useState } from 'react';
import Audioinfo from './audio/Audioinfo';
import { useSelector } from 'react-redux';

export default function Recommend() {
  const [musiclist, setmusiclist] = useState([]);
  const [page, setpage] = useState(1);
  const [loading, setloading] = useState(false);
  const [height, setHeight] = useState('513.5px');
  const [isover,setisover]=useState(false)
  const isverify=useSelector((state)=>state.userinfo.isverify)

  useEffect(() => {
    const userAgent = navigator.userAgent;

    // 根据浏览器类型和窗口大小设置不同的高度
    if (userAgent.includes('Edg')) {
        setHeight('488.5px')
    }
  }, []);

  async function loadMusic(pagenum) {
    if(isover){
      return;
    }
    setloading(true);
    try {
      const response = await fetch('https://api.music.coderyy.site/loadrecommend.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page: pagenum,  // 传递 page 数值
        }),
      });
      const data = await response.json();
      if(data.isover){
        setisover(true)
        return;
      }
      setmusiclist((prevlist) => [...prevlist, ...data.musics]);
      setpage(pagenum); // 更新页码
    } catch (error) {
      console.error('加载音乐失败', error);
    }
    setloading(false);
  }

  function handleScroll(e) {
    const isBottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    if (isBottom && !loading) {
      loadMusic(page + 1);  // 加载下一页
    }
  }

  useEffect(() => {
    loadMusic(page);  // 初次加载第一页
    // eslint-disable-next-line
  }, []);


  return (
    <>
      <main
        onScroll={handleScroll}
        style={{
          marginTop: '60px',
          marginLeft: '229px',
          marginBottom: '91.5px',
          padding: '20px',
          overflowY: 'auto',
          flexGrow: '1',
          height: height
        }}
      >
        {
          isverify&& 
          <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <h2>推荐</h2>
            </div>
            <ul style={{ marginTop: '18px', height: '600px' }}>
              {musiclist.map((item, index) => (
                <Audioinfo
                  props={item}
                  key={index}
                />
              ))}
            </ul>
          </>
        }
      </main>
    </>
  );
}
