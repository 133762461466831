import React, { useState, useRef, useEffect } from "react";

const VolumeControl = ({ audio }) => {
  const [volume, setVolume] = useState(0.33); // 默认音量
  const [showVolumeSlider, setShowVolumeSlider] = useState(false); // 控制滑块显示/隐藏
  const [isMuted, setIsMuted] = useState(false); // 静音状态
  const sliderRef = useRef(null);
  const iconRef = useRef(null);

  // 设置音量并保存到 localStorage
  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    if (audio) {
      audio.volume = newVolume;
    }
    localStorage.setItem("audioVolume", newVolume);
    setIsMuted(newVolume === 0); // 更新静音状态
  };

  // 切换静音状态
  const toggleMute = () => {
    if (audio) {
      if (isMuted) {
        audio.volume = volume; // 恢复音量
      } else {
        audio.volume = 0; // 静音
      }
    }
    setIsMuted(!isMuted);
  };

  // 鼠标放到音量图标上时显示滑块
  const handleMouseEnter = () => {
    setShowVolumeSlider(true);
  };

  // 鼠标离开音量图标或音量滑块时隐藏滑块
  const handleMouseLeave = () => {
    setShowVolumeSlider(false);
  };

  useEffect(() => {
    // 初始化音量
    const savedVolume = parseFloat(localStorage.getItem("audioVolume")) || 0.33;
    setVolume(savedVolume);
    if (audio) {
      audio.volume = savedVolume;
    }
  }, [audio]);

  useEffect(() => {
    // 确保音量和 audio 同步
    if (audio) {
      audio.volume = isMuted ? 0 : volume;
    }
  }, [volume, isMuted, audio]);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ position: "relative", display: "inline-block" }}>
      {/* 音量图标 */}
      <img
        ref={iconRef}
        src={
          isMuted
            ? "https://api.music.coderyy.site/music.coderyy.site's imgs/volumemute.png"
            : "https://api.music.coderyy.site/music.coderyy.site's imgs/volume.png"
        }
        style={{ width: "26px", height: "26px", cursor: "pointer" }}
        onClick={toggleMute}
      />

      {/* 音量滑块 */}
      {showVolumeSlider && (
        <div ref={sliderRef} style={styles.volumeSliderContainer}>
          <input
            type="range"
            min="0"
            max="1"
            value={isMuted ? 0 : volume} // 静音时显示为 0
            onChange={handleVolumeChange}
            style={styles.volumeSlider}
            step="0.01"
          />
          <span style={styles.volumeLabel}>
            {isMuted ? "Muted" : `${Math.round(volume * 100)}%`}
          </span>
        </div>
      )}
    </div>
  );
};

const styles = {
  volumeSliderContainer: {
    position: "absolute",
    bottom: "25px",
    left: "-8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "40px",
    padding: "10px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    zIndex: 10,
  },
  volumeSlider: {
    writingMode: "vertical-lr",
    direction: "rtl",
    width: "8px",
    height: "100px",
    background: "linear-gradient(to top, #4caf50, #ddd)",
    borderRadius: "5px",
    outline: "none",
    cursor: "pointer",
  },
  volumeLabel: {
    marginTop: "5px",
    fontSize: "14px",
    color: "#333",
  },
};

export default VolumeControl;
