import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setuserid, setusername } from '../store/modules/userinfomodule';

export default function LoginModal({ closemodal,loginSuccessful }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isRegister, setIsRegister] = useState(false);
    const [isCodeVerified, setIsCodeVerified] = useState(false); // 验证码是否验证
    const [code, setCode] = useState(""); // 存储验证码
    const [username, setUsername] = useState(""); // 存储用户名
    const [emailExists, setEmailExists] = useState(false); // 存储邮箱是否已存在
    const [emailValid, setEmailValid] = useState(true); // 邮箱格式是否有效
    const [passwordValid, setPasswordValid] = useState(true); // 密码是否符合要求
    const [emailSent, setEmailSent] = useState(false); // 标记验证码是否已发送
    const [isSendingCode, setIsSendingCode] = useState(false);
    const [countdown, setCountdown] = useState(0); // 用于倒计时显示秒数
    const signinemial = useRef(null);
    const signinpass = useRef(null);
    const signupemail = useRef(null);
    const signuppass = useRef(null);
    const codeRef=useRef(null)

    // 验证邮箱是否存在
    async function checkEmail(email) {
        try {
            const response = await fetch('https://api.music.coderyy.site/checkEmail.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email })
            });
            const data = await response.json();
            setEmailExists(data.exists);
            if(data.exists==='true')
            return true
        } catch (error) {
            window.alert("邮箱检查失败！", error);
            return true
        }
    }

    // 邮箱格式验证
    function validateEmail(email) {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return regex.test(email);
    }

    // 密码验证（长度至少8位且包含字母）
    function validatePassword(password) {
        const regex = /^(?=.*[a-zA-Z]).{8,}$/;
        return regex.test(password);
    }

    // 登录请求
    async function Signin(email, password) {
        if (!validateEmail(email)) {
            window.alert("请输入有效的邮箱地址");
            return;
        }
        if (!validatePassword(password)) {
            window.alert("密码格式错误：需至少8位并包含字母");
            return;
        }
        try {
            const response = await fetch('https://api.music.coderyy.site/Signin.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password })
            });
            const data = await response.json();
            if (data.status === 'success') {
                const userData = data.data;
                const jwt = userData.jwt; // 获取 JWT
                localStorage.setItem('authToken', jwt); // 使用 localStorage 保存令牌
                dispatch(setuserid(userData.userId));
                dispatch(setusername(userData.username));
                loginSuccessful();
                navigate('/mine');
            } else {
                window.alert(data.message || "登录失败！");
                closemodal();
            }
        } catch (error) {
            console.log("登录失败！", error);
            window.alert("登录失败!  ".error);
            closemodal();
        }
    }

    // 发送验证码
    async function sendVerifyCode(email) {  
        if(validatePassword(signuppass.current.value)){
            setPasswordValid(true)
        }else{
            setPasswordValid(false)
            return;
        }
        if (!validateEmail(email)) {
            setEmailValid(false);
            return;
        } else {
            setEmailValid(true);
        }
    
        if (isSendingCode) 
            return; // 避免重复提交
        setIsSendingCode(true);
        const emailExists = await checkEmail(email);
        if (emailExists) {
            setEmailExists(true);
            return;
        } else {
            setEmailExists(false);
        }
        try {
            setEmailSent(true)
            const response = await fetch('https://api.music.coderyy.site/sendVerifyCode.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email
                }),
            });
    
            const data = await response.json();
            if (data.status === 'email_sent') {
                window.alert("验证码已发送到您的邮箱，请查收！");
                setIsCodeVerified(false); 
                // 开始倒计时
                let seconds = 60;
                setCountdown(seconds); // 设置初始倒计时
                const interval = setInterval(() => {
                    seconds -= 1;
                    setCountdown(seconds);
                    if (seconds <= 0) {
                        clearInterval(interval); // 清除定时器
                    }
                }, 1000);
            } else {
                window.alert("发送验证码失败！");
            }
        } catch (error) {
            window.alert("发送验证码失败！", error);
        } finally {
            setIsSendingCode(false);
        }
    }
    

    // 验证验证码
    async function verifyCode(email, code) {
        if (!emailSent) {
            window.alert("请先发送验证码");
            return;
        }
        try {
            const response = await fetch('https://api.music.coderyy.site/verifyCode.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, code })
            });
            const data = await response.json();
            if (data.status === 'code_verified') {
                setIsCodeVerified(true);
            } else {
                setIsCodeVerified(false);
                window.alert("验证码错误！");
            }
        } catch (error) {
            window.alert("验证码验证失败！", error);
        }
    }

    // 完成注册
    async function completeSignup(email, username, password) {
        try {
            const response = await fetch('https://api.music.coderyy.site/completeSignup.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, username, password })
            });
            const data = await response.json();
            if (data.status === 'success') {
                window.alert("注册成功")
                Signin(email, password);
            } else {
                window.alert("注册失败！");
                console.log(data.error);
                
            }
        } catch (error) {
            window.alert("注册失败！", error);
        }
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: '1000',
            }}
        >
            <div
                style={{
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    padding: '20px',
                    width: '400px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    position: 'relative',
                    textAlign: 'center',
                }}
            >
                {/* Close button */}
                <button
                    onClick={closemodal}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        fontSize: '20px',
                        color: '#888',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                    }}
                >
                    ×
                </button>

                {/* 判断显示登录或注册界面 */}
                {isRegister ? (
                    // 注册界面
                    <div style={{ padding: '20px' }}>
                        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '46px', marginTop: '30px' }}>coderyy's music</h2>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: '#f7f7f7',
                                borderRadius: '30px',
                                padding: '5px 10px',
                                marginBottom: '10px',
                            }}
                        >
                            <input
                                ref={signupemail}
                                type="text"
                                placeholder="请输入邮箱"
                                style={{
                                    flex: '1',
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    outline: 'none',
                                    padding: '10px',
                                    borderRadius: '30px',
                                }}
                            />
                        </div>
                        {!emailValid && <p style={{ color: 'red' }}>请输入有效的邮箱地址</p>}
                        {emailExists && <p style={{ color: 'red' }}>此邮箱已被注册</p>}

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: '#f7f7f7',
                                borderRadius: '30px',
                                padding: '5px 10px',
                                marginBottom: '10px',
                            }}
                        >
                            <input
                                ref={signuppass}
                                type="password"
                                placeholder="设置登录密码"
                                style={{
                                    flex: '1',
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    outline: 'none',
                                    padding: '10px',
                                    borderRadius: '30px',
                                }}
                            />
                        </div>
                        {!passwordValid && <p style={{ color: 'red' }}>密码需至少8位且包含字母</p>}

                        {/* 验证码部分 */}
                        {!isCodeVerified && (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <input
                                        ref={codeRef}
                                        type="text"
                                        placeholder="请输入验证码"
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                        style={{
                                            flex: '1',
                                            padding: '10px',
                                            borderRadius: '30px',
                                            marginRight: '10px',
                                        }}
                                    />
                                    <button
                                        onClick={() => sendVerifyCode(signupemail.current.value)}
                                        disabled={countdown > 0} // 在倒计时期间禁用按钮
                                        style={{
                                            padding: '10px 20px',
                                            backgroundColor: '#ff6d6d',
                                            border: 'none',
                                            borderRadius: '30px',
                                            color: '#fff',
                                            fontSize: '16px',
                                            cursor: countdown > 0 ? 'not-allowed' : 'pointer'
                                        }}
                                    >
                                        {countdown > 0 ? `${countdown}s` : "发送验证码"}
                                    </button>
                                </div>
                            </div>
                        )}

                        {/* 验证通过后，继续填写用户名 */}
                        {isCodeVerified && (
                            <div>
                                <input
                                    type="text"
                                    placeholder="请输入用户名"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '30px',
                                        marginBottom: '20px',
                                    }}
                                />
                            </div>
                        )}

                        {/* 注册按钮 */}
                        {!isCodeVerified && (
                            <button
                            onClick={()=>verifyCode(signupemail.current.value,codeRef.current.value)}
                            style={{
                                width: '100%',
                                padding: '10px',
                                background: 'linear-gradient(to right, #fa5252, #ff6d6d)',
                                border: 'none',
                                borderRadius: '30px',
                                color: '#fff',
                                fontSize: '16px',
                                cursor: isSendingCode || emailExists || !emailValid || !passwordValid ? 'not-allowed' : 'pointer',
                                opacity: isSendingCode || emailExists || !emailValid || !passwordValid ? 0.6 : 1,
                            }}
                        >
                            注册
                        </button>
                        )}


                        {isCodeVerified && (
                            <button
                            onClick={() =>
                                completeSignup(signupemail.current.value, username, signuppass.current.value)
                            }
                            disabled={!isCodeVerified}
                            style={{
                                width: '100%',
                                padding: '10px',
                                background: 'linear-gradient(to right, #fa5252, #ff6d6d)',
                                border: 'none',
                                borderRadius: '30px',
                                color: '#fff',
                                fontSize: '16px',
                            }}
                        >
                            完成注册
                        </button>
                        )}

                    </div>
                ) : (
                    // 登录界面
                    <div>
                        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '46px', marginTop: '30px' }}>coderyy's music</h2>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: '#f7f7f7',
                                borderRadius: '30px',
                                padding: '5px 10px',
                                marginBottom: '10px',
                            }}
                        >
                            <input
                                ref={signinemial}
                                type="text"
                                placeholder="请输入邮箱"
                                style={{
                                    flex: '1',
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    outline: 'none',
                                    padding: '10px',
                                    borderRadius: '30px',
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: '#f7f7f7',
                                borderRadius: '30px',
                                padding: '5px 10px',
                                marginBottom: '10px',
                            }}
                        >
                            <input
                                ref={signinpass}
                                type="password"
                                placeholder="请输入密码"
                                style={{
                                    flex: '1',
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    outline: 'none',
                                    padding: '10px',
                                    borderRadius: '30px',
                                }}
                            />
                        </div>
                        <button
                            onClick={() => Signin(signinemial.current.value, signinpass.current.value)}
                            style={{
                                width: '100%',
                                padding: '10px',
                                background: 'linear-gradient(to right, #fa5252, #ff6d6d)',
                                border: 'none',
                                borderRadius: '30px',
                                color: '#fff',
                                fontSize: '16px',
                                cursor: 'pointer',
                            }}
                        >
                            登录
                        </button>
                        <div
                            onClick={() => setIsRegister(true)}
                            style={{
                                color: '#888',
                                fontSize: '14px',
                                cursor: 'pointer',
                                marginTop: '20px',
                            }}
                        >
                            还没有账号？点击注册
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}