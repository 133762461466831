import { useLocation } from 'react-router-dom'
import Asidebutton from './Asidebutton'
import { useEffect, useState } from 'react'

const asidelist=[
    {id:'1',content:'推荐',href:'/recommend'},
    {id:'2',src:'',content:'游戏',href:'/game'},
    {id:'3',src:'',content:'漫游',href:'/manyou'},
    {id:'4',src:'',content:'我喜欢的音乐',href:'/mylike'}
]

function Aside(){
    const location=useLocation()
    const [active,setactive]=useState()

    useEffect(()=>{
        if(location.pathname==='/recommend'||location.pathname==='/'){
            setactive('1')
        }
        else if(location.pathname==='/game'){
            setactive('2')
        }
        else if(location.pathname==='/manyou'){
            setactive('3')
        }
        else if(location.pathname==='/mylike'){
            setactive('4')
        }
        else if(location.pathname==='/mine'||location.pathname==='/searchmusic'){
            setactive('0')
        }
    },[location.pathname])
    const style ={
        zIndex: '100'
    }
    return(
        <div style={{width:'229px',backgroundColor:'#f4f4f4',
            borderRight: '1px solid #ccc',
            position: 'fixed',
            top: '0',
            bottom: '55px'
        }}>
            <div style={{display:'flex',marginBottom:'18px',padding:'26px',lineHeight:'36px',justifyContent:'center'}}>
                <img src="https://api.music.coderyy.site/music.coderyy.site's imgs/logo.png" alt="" style={{width:'36px',height:'36px'}} />
                <a  href="https://music.coderyy.site" style={{marginLeft:'18px'}}>coderyy's music</a>
            </div>
            <div style={style}>
                <ul style={{
                    marginTop:'85px',
                    display : 'flex',
                    flexDirection:'column',
                    gap:'30px',
                    backgroundColor:'rgb(240,243,246)',
                    color:'rgba(120,127,141,255)',
                    width:'180px'
                }}>
                    {asidelist.map(item => (
                    <li key={item.id} style={{borderRadius:'10px',paddingLeft:'48px',height:'40px',lineHeight:'40px', backgroundColor: item.id===active ? 'rgba(253,62,74,255)' :'rgba(240, 243, 246, 0.5)'}}>
                        <Asidebutton props={item} />
                    </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Aside