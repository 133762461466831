import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Game() {
  const [height, setHeight] = useState('513.5px');

  useEffect(() => {
    const userAgent = navigator.userAgent;
    // 根据浏览器类型和窗口大小设置不同的高度
    if (userAgent.includes('Edg')) {
        setHeight('488.5px')
    }
  }, []);

  return (
    <main
      style={{
        marginTop: '60px',
        marginLeft: '229px',
        marginBottom: '91.5px',
        padding: '20px',
        overflowY: 'auto',
        flexGrow: '1',
        height: height
      }}
    >
      <h2 style={{textAlign:'center'}}>小游戏</h2>
      <ul style={{marginTop:'50px',marginLeft:'30px'}}>
        <li>
          <div>
            <Link to='/wuziqi'   style={{
                display: 'inline-block',
                padding: '10px 20px',
                backgroundColor: '#007bff',
                color: '#fff',
                textDecoration: 'none',
                borderRadius: '5px',
                textAlign: 'center',
                cursor: 'pointer'
              }}
            >
              五子棋
            </Link>
          </div>
        </li>
      </ul>
    </main>
  );
}
