import { createSlice } from "@reduxjs/toolkit";

const userSlice=createSlice({
    name:'userinfo',
    initialState:{
        id:null,
        username:null,
        email:null,
        vip:null,
        isverify:false,
        place:null,
        birthday:null,
        gender:null,
        description:null,
        userimg:null,
        myplaylist:[]
    },
    reducers:{
        setuserid(state,action){
            state.id=action.payload
        },
        setusername(state,action){
            state.username=action.payload
        },
        setuseremail(state,action){
            state.email=action.payload
        },
        setuservip(state,action){
            state.vip=action.payload
        },
        setisverify(state,action){
            state.isverify=action.payload
        },
        setplace(state,action){
            state.place=action.payload
        },
        setbirthday(state,action){
            state.birthday=action.payload
        },
        setgender(state,action){
            state.gender=action.payload
        },
        setdescription(state,action){
            state.description=action.payload
        },
        setuserimg(state,action){
            state.userimg=action.payload
        },
        setmyplaylist(state,action){
            state.myplaylist=action.payload
        }
    }
})

export const {setuserid,setuseremail,setmyplaylist,setbirthday,setdescription,setgender,setplace,setuserimg,setusername,setuservip,setisverify} = userSlice.actions
export default userSlice.reducer