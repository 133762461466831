import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const parseLyrics = (lyricsStr) => {
    // 以换行符分割歌词
    const lines = lyricsStr.split('\n');

    return lines.reduce((acc, line, index) => {
        // 去除每行两端的空格
        line = line.trim();

        // 如果是空行或者不是以时间戳开头的行，跳过
        if (!line || !line.startsWith('[')) {
            return acc;
        }

        // 更新正则表达式，支持带毫秒和不带毫秒的时间戳
        const matches = line.match(/\[(\d{2}):(\d{2})(?:\.(\d{1,3}))?\](.+)/);
        
        if (matches) {
            const minutes = matches[1];
            const seconds = matches[2];
            const milliseconds = matches[3] ? matches[3] : '0'; // 如果有毫秒则使用它，否则默认为0

            // 将时间戳转换为字符串，并存储歌词
            acc.push({
                time: `${minutes}:${seconds}${milliseconds ? '.' + milliseconds : ''}`,  // 时间戳保持为字符串
                text: matches[4],     // 歌词
            });
        } else {

        }
        return acc;
    }, []);
};




// 创建异步操作（异步请求歌词）
export const fetchLyrics = createAsyncThunk('audioinfo/fetchLyrics', async (lyricsname) => {
    const response = await fetch('https://api.music.coderyy.site/loadmusiclrc.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ file: lyricsname })
    });
    const data = await response.text();
    // 解析歌词
    const parsedLyrics = parseLyrics(data);
    
    return parsedLyrics;
});

// 创建 Redux slice
const audioinfoSlice = createSlice({
    name: 'audioinfo',
    initialState: {
        lyrics: [], 
        coversrc: 'https://api.music.coderyy.site/imgs/我们.jpg',
        musicname: '我们',
        musicauthor: '乐柠组合',
        musicduration: '03:39',
        musicsrc: 'https://api.music.coderyy.site/musics/我们.mp3',
        lrcname: '我们.lrc',
        isplaying: false,
        currentmusicid:2,
        allmusiclist:[],
        targetsong:null,
        mylikelist:[],
        needtoplay:false,
        myliketot:null
    },
    reducers: {
        setcoversrc(state, action) {
            state.coversrc = action.payload;
        },
        setmusicname(state, action) {
            state.musicname = action.payload;
        },
        setmusicauthor(state, action) {
            state.musicauthor = action.payload;
        },
        setmusicduration(state, action) {
            state.musicduration = action.payload;
        },
        setmusicsrc(state, action) {
            state.musicsrc = action.payload;
        },
        setlrcname(state, action) {
            state.lrcname = action.payload;
        },
        setisplaying(state, action) {
            state.isplaying = action.payload;
        },
        setcurrentmusicid(state,action){
            state.currentmusicid=action.payload;
        },
        setallmusiclist(state,action){
            state.allmusiclist=action.payload;
        },
        settargetsong(state,action){
            state.targetsong=action.payload;
        },
        setmylikelist(state,action){
            state.mylikelist=action.payload;
        },
        setneedtoplay(state,action){
            state.needtoplay=action.payload;
        },
        setmyliketot(state,action){
            state.myliketot=action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLyrics.fulfilled, (state, action) => {
                state.lyrics = action.payload;
            });
    },
});

export const { setcoversrc,setneedtoplay,setmyliketot,settargetsong,setlrcname,setmylikelist,setallmusiclist,setcurrentmusicid,setmusicauthor, setmusicduration, setmusicname, setmusicsrc, setisplaying } = audioinfoSlice.actions;
export default audioinfoSlice.reducer;
