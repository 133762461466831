import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBar from "./SerachBar";
import LoginModal from "../login/LoginModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setisverify } from "../store/modules/userinfomodule";
import { parseBlob } from "music-metadata-browser";
import process from 'process';
import { Buffer } from 'buffer';

export default function Header() {
    const location=useLocation()
    const username = useSelector((state) => state.userinfo.username);
    const isverify = useSelector((state) => state.userinfo.isverify);
    const navigate=useNavigate()
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const dispatch=useDispatch()
    global.process = process;
    window.Buffer = Buffer;

    const openLoginModal = () => {
        if(!isverify){
            setIsLoginModalOpen(true);
        }else{
            navigate('/mine')
        }
    }

    const closeLoginModal = () => {
        setIsLoginModalOpen(false);
    }

    function loginSuccessful(){
        dispatch(setisverify(true))
        closeLoginModal()
    }

    function quitLogin(){
        let result=window.confirm("你确定要退出吗？")
        if(result){
            localStorage.removeItem('authToken');
            window.location.reload()
        }else{
            
        }
    }

    async function startUploadProcess() {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "audio/*"; // 限制为音频文件
    
        input.onchange = async () => {
            const file = input.files[0];
            if (!file) {
                alert("未选择任何文件！");
                return;
            }
    
            try {
                // 提取音频元数据
                let musicauthor = "未知艺术家";
                let album = "未知专辑";
    
                const metadata = await parseBlob(file);
                musicauthor = metadata.common.artist || "未知艺术家";
                album = metadata.common.album || "未知专辑";
    
                // 使用 Audio 对象获取音频时长
                const audio = new Audio();
                audio.src = URL.createObjectURL(file);
                await new Promise((resolve) => (audio.onloadedmetadata = resolve));
                const musicduration = audio.duration;
                const minutes = Math.floor(musicduration / 60); // 获取分钟
                const seconds = Math.round(musicduration % 60); // 获取秒数并四舍五入
                
                // 确保分钟和秒数都是两位数（例如 02:04 而不是 2:4）
                const formattedDuration = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    
                // 上传文件到服务器
                const uploadUrl = "https://api.music.coderyy.site/upload.php";
                const formData = new FormData();
                formData.append("file", file);
    
                const uploadResponse = await fetch(uploadUrl, {
                    method: "POST",
                    body: formData,
                });
    
                if (!uploadResponse.ok) {
                    alert(`上传失败，服务器返回状态码: ${uploadResponse.status}`);
                    return;
                }
    
                const uploadResult = await uploadResponse.json();
                if (uploadResult.status !== "success") {
                    alert(`上传失败: ${uploadResult.message}`);
                    return;
                }
    
                // 构造文件路径
                const fileName = file.name;
                const musicName = fileName.substring(0, fileName.lastIndexOf("."));
                const musicSrc = `https://api.music.coderyy.site/musics/${fileName}`;
                const lrcSrc = `https://api.music.coderyy.site/lyrics/${musicName}.lrc`;
                const coversrc = `https://api.music.coderyy.site/imgs/${musicName}.jpg`;
    
                // 插入数据到数据库
                const insertUrl = "https://api.music.coderyy.site/insertSong.php";
                const insertPayload = {
                    musicsrc: musicSrc,
                    lrcname: lrcSrc,
                    coversrc: coversrc,
                    album: album,
                    musicname: musicName,
                    musicauthor: musicauthor,
                    musicduration: formattedDuration,
                };
    
                const insertResponse = await fetch(insertUrl, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(insertPayload),
                });
    
                if (!insertResponse.ok) {
                    alert(`插入数据库失败，服务器返回状态码: ${insertResponse.status}`);
                    return;
                }
    
                const insertResult = await insertResponse.json();
                if (insertResult.status !== "success") {
                    alert(`插入数据库失败: ${insertResult.message}`);
                    return;
                }
    
                alert(`文件 ${file.name} 上传并保存成功！`);
            } catch (error) {
                alert(`处理过程中出现错误: ${error.message}`);
            }
        };
    
        input.click();
    }
    
      



    return (
        <>
            <header style={{
                height: '60px',
                width: 'calc(100% - 229px)', // 根据侧边栏宽度调整
                position: 'fixed',
                top: '0',
                left: '229px', // 根据侧边栏位置调整
                backgroundColor: '#f7f7f7',
                borderBottom: '1px solid #e1e1e1',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 30px',
                boxSizing: 'border-box',
                zIndex: '1000',
                boxShadow: '0 1px 5px rgba(0, 0, 0, 0.1)'
            }}>
                {/* 搜索栏部分 */}
                <SearchBar/>

                {/* 用户和图标部分 */}
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '15px',
                    cursor: 'pointer'
                }}>
                    {/* 用户名部分 */}
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        position:'absolute',
                        left:'55%'
                    }} onClick={openLoginModal}>
                        <img style={{width:'36px',height:'36px'}} src="https://api.music.coderyy.site/music.coderyy.site's imgs/login.png" alt="登陆" />
                        <span style={{ marginLeft:'12px',fontSize: '14px', color: '#333'}}>{username}</span>
                    </div>

                    {
                        isverify&&(location.pathname==='/recommend'|| location.pathname==='/') ?
                        <div style={{position:'absolute',right:'14%'}} onClick={startUploadProcess}>
                            <img style={{width:'36px',height:'36px'}} src="https://api.music.coderyy.site/music.coderyy.site's imgs/uploadmusic.png" alt="" />
                        </div>
                        :
                        ''
                    }

                    {
                        isverify? 
                        <div onClick={quitLogin}>
                            <img style={{width:'36px',height:'36px'}} src="https://api.music.coderyy.site/music.coderyy.site's imgs/close.png" alt="退出登陆" />
                        </div>
                        : 
                        ''
                    }

                </div>
            </header>

            {/* 登录模态框 */}
            {isLoginModalOpen && <LoginModal closemodal={closeLoginModal} loginSuccessful={loginSuccessful} />}
        </>
    );
}
