import { useEffect, useState } from 'react';
import Audioinfo from './audio/Audioinfo';
import { useDispatch, useSelector } from 'react-redux';
import { settargetsong } from './store/modules/audioinfomodule';
import { useNavigate } from 'react-router-dom';

export default function Mylike() {
  const [musiclist, setmusiclist] = useState([]);
  const [page, setpage] = useState(1);
  const [loading, setloading] = useState(false);
  const [height, setHeight] = useState('513.5px');
  const [isover,setisover]=useState(false)
  const isverify=useSelector((state)=>state.userinfo.isverify)
  const mylikelist=useSelector(state=>state.audioinfo.mylikelist)
  const userid=useSelector(state=>state.userinfo.id)
  const navigate=useNavigate()
  const dispatch=useDispatch()

  function serchformusic(musicname){
    const targetsong=mylikelist.find((music)=>music.musicname===musicname);
    if(!targetsong){
      window.alert("歌曲未找到！");
      return;
    }
    dispatch(settargetsong(targetsong))
    navigate('/searchmusic')
  }

  useEffect(() => {
    const userAgent = navigator.userAgent;

    // 根据浏览器类型和窗口大小设置不同的高度
    if (userAgent.includes('Edg')) {
        setHeight('488.5px')
    }
  }, []);

  const handlekeypress=(event)=>{
    if(event.key==='Enter'){
      const musicvalue=event.target.value;
      serchformusic(musicvalue)
      event.target.value=''
    }
  }

  async function loadMusic(pagenum) {
    if(isover){
      return;
    }
    setloading(true);
    try {
      const response = await fetch('https://api.music.coderyy.site/loadmylike.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userid:userid,
          page: pagenum
        }),
      });
      const data = await response.json();
      if(data.isover){
        setisover(true)
        return;
      }
      // 合并前面的数据和新加载的音乐数据
      setmusiclist((prevlist) => [...prevlist, ...data.musics]);
      setpage(pagenum); // 更新页码
    } catch (error) {
      console.error('加载音乐失败', error);
    }
    setloading(false);
  }

  function handleScroll(e) {
    const isBottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    if (isBottom && !loading) {
      loadMusic(page + 1);  // 加载下一页
    }
  }

  useEffect(() => {
    if(userid){
      loadMusic(page);
    }
  }, [userid]);


  return (
    <>
      <main
        onScroll={handleScroll}
        style={{
          marginTop: '60px',
          marginLeft: '229px',
          marginBottom: '91.5px',
          padding: '20px',
          overflowY: 'auto',
          flexGrow: '1',
          height: height
        }}
      >
        {
          isverify&& 
          <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <h2>我喜欢的音乐</h2>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '30px', gap: '26px' }}>
              <span style={{ width: '42.5px', color: 'lightblue',cursor:'pointer' }}>歌曲</span>
              <span style={{ width: '42.5px',cursor:'pointer' }}>评论</span>
              <span style={{ width: '55px',cursor:'pointer' }}>收藏者</span>
                <input
                onKeyDown={handlekeypress}
                  style={{
                    marginLeft: '550px',
                    padding: '8px 16px',
                    border: '1px solid #d3d3d3',
                    borderRadius: '20px',
                  }}
                  type="text"
                />
            </div>
            <ul style={{ marginTop: '18px', height: '600px' }}>
              {musiclist.map((item, index) => (
                <Audioinfo
                  props={item}
                  key={index}
                  orderid={index}
                />
              ))}
            </ul>
          </>
        }
      </main>
    </>
  );
}
