import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { setcoversrc, setcurrentmusicid,  setlrcname, setmusicauthor, setmusicduration, setmusicname, setmusicsrc, setmylikelist, setneedtoplay } from '../store/modules/audioinfomodule';

export default function Audioinfo({ props , orderid }) {
  orderid=orderid+1
  const { musicsrc,coversrc, musicname, musicauthor, album, musicduration,lrcname,musicid } = props;
  const dispatch =useDispatch()
  const [showOptions, setShowOptions] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const playingmusicsrc = useSelector((state) => state.audioinfo.musicsrc);
  const mylikelist=useSelector(state=>state.audioinfo.mylikelist)
  const islike = mylikelist.some((music) => music.musicid === musicid);
  const userid=useSelector(state=>state.userinfo.id)
  const isplaying=useSelector(state=>state.audioinfo.isplaying)


  async function loadallmylike() {
    try{
        const response=await fetch("https://api.music.coderyy.site/loadallmylike.php",{
            method:'post',
            body:JSON.stringify({
              userid:userid
            })
        })
        const data=await response.json();
        dispatch(setmylikelist(data.musics))
    }catch(error){
        window.alert(error);
    }
}

  async function addtomylike(){
    const response=await fetch("https://api.music.coderyy.site/addtomylike.php",{
      method:'post',
      body:JSON.stringify({
        userid:userid,
        musicid:musicid
      })
    })
    const data=await response.json()
    if(data.status==='success'){
      window.alert("添加成功！")
      loadallmylike()
    }
    else{
      window.alert("添加失败！")
    }
  }

  async function deletefrommylike() {
    const response=await fetch("https://api.music.coderyy.site/deletefrommylike.php",{
      method:'post',
      body:JSON.stringify({
        userid:userid,
        musicid:musicid
      })
    })
    const data=await response.json()
    if(data.status==='success'){
      window.alert("移除成功！")
      loadallmylike()
    }
    else{
      window.alert("移除失败！")
    }
  }

  const handlePlay = () => {
      if(musicsrc===playingmusicsrc&&isplaying){
        return;
      }
      dispatch(setcurrentmusicid(musicid-1))
      dispatch(setmusicsrc(musicsrc))
      dispatch(setmusicname(musicname))
      dispatch(setmusicauthor(musicauthor))
      dispatch(setcoversrc(coversrc))
      dispatch(setmusicduration(musicduration))
      dispatch(setlrcname(lrcname))
      dispatch(setneedtoplay(true))
      setTimeout(() => {
        dispatch(setneedtoplay(false))
      }, 100);
  };

  const handleMouseEnter = () => {
    setShowOptions(true);
  };

  const handleMouseLeave = () => {
    setShowOptions(false);
    setShowMoreOptions(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px 20px',
        borderBottom: '1px solid #f0f0f0',
        backgroundColor: '#fff',
        fontSize: '14px',
        color: '#333',
        position: 'relative'
      }}
    >
      {/* 编号 */}
      {
        orderid?
        <span style={{ width: '30px', textAlign: 'center', color: '#888' }}>
          {orderid < 10 ? `0${orderid}` : orderid}
        </span>
      :
        <span style={{ width: '30px', textAlign: 'center', color: '#888' }}>
          {musicid < 10 ? `0${musicid}` : musicid}
        </span>
      }

      {/* 播放按钮和封面图 */}
      <div style={{ display: 'flex', alignItems: 'center', width: '50px' }}>
        <button
          onClick={()=>handlePlay()}
          style={{
            width: '24px',
            height: '24px',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer'
          }}
        >
          ▶️
        </button>
        <img
          src={coversrc}
          alt={musicname}
          style={{ width: '40px', height: '40px', borderRadius: '5px', marginLeft: '10px' }}
        />
      </div>

      {/* 标题和歌手 */}
      <div style={{ flex: 2, paddingLeft: '46px' }}>
        <div style={{ fontWeight: 'bold' }}>{musicname}</div>
        <div style={{ color: '#888' }}>{musicauthor}</div>
      </div>

      {/* 专辑 */}
      <span style={{ flex: 1, color: '#888', textAlign: 'center' }}>
        {album}
      </span>

      <div style={{ marginLeft:'45px',display:'flex',alignItems:'center',width: '30px', cursor: 'pointer' }}>
        {
          islike?
            <img onClick={deletefrommylike} style={{marginTop:'2.2px',width:'18px',height:'18px'}} src="https://api.music.coderyy.site/music.coderyy.site's imgs/like.png" alt="喜欢" />
          :
            <img onClick={addtomylike} style={{marginTop:'2.2px',width:'18px',height:'18px'}} src="https://api.music.coderyy.site/music.coderyy.site's imgs/dislike.png" alt="不喜欢" />
        }
      </div>

      {/* 时长 */}
      <span style={{ marginLeft:'20px',width: '50px', textAlign: 'center', color: '#888' }}>{musicduration}</span>

      {/* 功能区*/}
      {showOptions && (
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            gap: '10px',
            backgroundColor: '#fff',
            padding: '5px 10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            zindex: 10
          }}
        >
          <span style={{ cursor: 'pointer' }}>❤️</span> {/* 收藏 */}
          <span style={{ cursor: 'pointer' }}>⬇️</span> {/* 下载 */}
          <span style={{ cursor: 'pointer' }}>💬</span> {/* 评论 */}
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => setShowMoreOptions(!showMoreOptions)}
          >
            ⋮
          </span>

          {showMoreOptions && (
            <div
              style={{
                position: 'absolute',
                top: '100%',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: '#fff',
                padding: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                borderRadius: '8px',
                marginTop: '5px',
                zindex: 20
              }}
            >
              <div style={{ padding: '5px 0', cursor: 'pointer' }}>分享</div>
              <div style={{ padding: '5px 0', cursor: 'pointer' }}>添加到播放列表</div>
              <div style={{ padding: '5px 0', cursor: 'pointer' }}>查看艺术家</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
